import React, {Fragment}from 'react';
import {assertNowIsBetween} from 'Utils/commonUtils';

const HolidayMainContent = (props) => {
    return (
        <Fragment>
            { HolyWeekMessage() }
        </Fragment>
    );
};

export const ChristmasBreakMessage = () => {
  return (
    <Fragment>
      <p>Holiday Operations. </p>
      <p>Please be advised that SMB Delivers will not be available on December 25, 2024 and January 1, 2025.</p>
      <p>Plan ahead and book your orders through <a href='https://www.smbdelivers.com/'>www.SMBDelivers.com</a> or (02)8632-2337.</p>
    </Fragment>
  )
}

export const NonHolyWeekMessage = () => {
  return (
    <Fragment>
        <p>Rest assured that we put everyone's health and safety first, and fully comply with the Government's enhanced community quarantine requirements.</p>
        <p>
          Order through <a href='https://www.smbdelivers.com/'>www.SMBDelivers.com</a> or 028632BEER(2337)
        </p>
    </Fragment>
  );
};

export const HolyWeekMessage = () => {
  return (
    <Fragment>
      <p>In solemn observance of the Holy Week our delivery service will not be available from <br /><strong>April 17  and 18, 2025</strong>.</p>
      <p>You may pre-book your beer orders through <a href='https://www.smbdelivers.com/'>www.SMBDelivers.com</a></p>
    </Fragment>
  );
};

export const NormalHolidayMessage = () => {
  return (
    <Fragment>
      <p>Please be advised that SMB Delivers will not be available from October 29 to 30, 2023 to observe the Liquor Ban during the Philippine Elections.</p>
      <p>You may pre-book your orders through <a href="https://www.smbdelivers.com/">www.SMBDelivers.com</a></p>
    </Fragment>
  )
}

export const NovemberHolidayMessage = () => {
  return (
    <Fragment>
      <p>In observance of All Saints' Day, our delivery service will not be available on November 1, 2024.</p>
      <p>You may pre-book your beer orders at <a href="https://www.smbdelivers.com/">www.SMBDelivers.com</a>.</p>
    </Fragment>
  )
}

export default HolidayMainContent;

