import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import moment from 'moment';
import { isNil } from 'lodash';
import { Select } from 'antd';

import './Home.scss';
import { getProducts } from 'Services/productServices';
import { isWithinHolidaySeason, muziklabanFestival } from 'Utils/holidayUtils';
import GradientBtn from 'Components/GradientBtn/GradientBtn';
import RecoProducts from 'Components/RecoProducts/RecoProducts';
import HolidayContent from 'Components/HolidayContent/HolidayContent';
import DateBasedDisplay from 'Components/DateBasedDisplay/DateBasedDisplay';
import HeroNotice from 'Components/HeroNotice/HeroNotice';
import OperatingTimeContent from 'Components/OperatingTimeContent/OperatingTimeContent';
import CategoryGateModal from './CategoryGateModal';
import LocationAndCategoryComponent from './Components/LocationAndCategoryComponent';
import CookiePrompt from 'Components/Cookie/CookiePrompt';
import TypeAhead from 'Components/TypeAhead/TypeAhead';
import DynamicBanner from 'Components/DynamicBanner/DynamicBanner';

import PickupGateModal from './PickupGateModal';
import DeliveryGateModal from './DeliveryGateModal';
import HolidayMainContent from './HolidayMainContent';

import heroHandle from 'Assets/images/hero-handle.png';
import heroMessage from 'Assets/images/default-magnets/hero-message.png';

const { 
  LOCATION_BARANGAY_ID_COOKIE_NAME,
  CATEGORY_GATE_ID_COOKIE_NAME
 } = process.env;


class HomeTPL extends Component {
  constructor(props) {
    super(props);
    this.state = {
      recoProducts: [],
      bestProducts: [],
    };    
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.fetchProducts();
  }

  async fetchProducts() {
    try {

      const { cookies } = this.props;
      let barangayId = cookies.get(LOCATION_BARANGAY_ID_COOKIE_NAME);
      let packaging = cookies.get(CATEGORY_GATE_ID_COOKIE_NAME);
      let filters = `?barangay=${barangayId}&packaging=${packaging}`;

      const response = await getProducts(filters);

      this.setState({
        recoProducts: response['recommended_products'],
        bestProducts: response['best_sellers'],
      });
    } catch (error) {
      this.setState({
        recoProducts: [],
        bestProducts: [],
      });
    }
  }

  handleAddToCart = (productId, quantity) => {
    return () => {
      console.log(quantity, 'add to cart');
      let { addToCart } = this.props;
      addToCart(productId, quantity);
    };
  };

  render() {
    const { recoProducts, bestProducts } = this.state;
    const { auth, showModal, cookies, changeLocationHandler } = this.props;
    const isLoggedIn = !isNil(auth) && 'isLoggedIn' in auth ? auth.isLoggedIn : false;
    const isMuziklaban = isWithinHolidaySeason(muziklabanFestival) === false ? 'hero-bg-container normal' : 'hero-bg-container';
    
    // Allow testing via URL like ?testdate=2025-04-11
    const urlParams = new URLSearchParams(window.location.search);
    const testDate = urlParams.get('testdate');
    const currDate = testDate ? moment(testDate) : moment();

    const bannerStartDate = moment('2025-04-09', 'YYYY-MM-DD');
    const bannerEndDate = moment('2025-04-20', 'YYYY-MM-DD');

    // check if banner should show (inclusive of start and end dates)
    const isBannerShouldShow = moment(currDate).isBetween(bannerStartDate, bannerEndDate, 'day', '[]');

    return (
      <>
        <Helmet>
          <title>SMB Delivers</title>
          <meta name='description' content={`SMBDelivers.com offers delivery services of your favourite San Miguel Beer brands...`} />
        </Helmet>
        <LocationAndCategoryComponent isLoggedIn={isLoggedIn} changeLocationHandler={changeLocationHandler} cookies={cookies}/>
        
        
        <DynamicBanner showButtonLink={true} />

        {/* Selected days notice banner */}
        { isBannerShouldShow ? (
          <section className='notice'>
           <div className='account-notice'>
             <h1>Notice to our Members</h1>
             <HolidayContent useLocaltime showWhenNotHoliday={true}>
               <HolidayMainContent/>
             </HolidayContent>
           </div>
          </section>
        ) : null } 
        

        {isLoggedIn && recoProducts.length > 0 && <RecoProducts products={recoProducts} sectionTitle='RECOMMENDED FOR YOU' shouldLinkToProducts />}

        {bestProducts.length > 0 && <RecoProducts products={bestProducts} sectionTitle='BEST SELLERS' shouldLinkToProducts />}
      </>
    );
  }
}

export default HomeTPL;
