import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { Checkbox } from 'antd';
import { isNil } from 'lodash';
import { Link } from 'react-router-dom';

import './CartSummary.scss';
import { printStandardDecimals } from 'Utils/cartUtils';
import { isHolidayToday, holidays } from 'Utils/holidayUtils';
import GradientBtn from 'Components/GradientBtn/GradientBtn';
import FormItemWrapper from 'Components/Form/FormItemWrapper/FormItemWrapper';
import HolidayContent from 'Components/HolidayContent/HolidayContent';
import OperatingTimeContent from 'Components/OperatingTimeContent/OperatingTimeContent';
import SummaryItemGroupContainer from './SummaryItemGroupContainer';

const CartSummaryTPL = (props) => {
  const { cart,
          cartSummary,
          handleSwitchToCart,
          isInCheckout,
          handleSubmitOrder,
          canSubmitOrder
       } = props;

  const subtotal = !isNil(cartSummary) && 'subtotal' in cartSummary ? cartSummary.subtotal : undefined;
  const deposits = !isNil(cartSummary) &&  'deposits' in cartSummary ? cartSummary.deposits : undefined;
  const itemsInCart = !isNil(cartSummary) ? cartSummary['items_in_cart'] : [];
  const itemsInCartCost = itemsInCart.reduce((previousValue, nextValue) =>
                            previousValue + nextValue.amount, 0);
  const deliveryFee = cart.delivery_fee;
  const depositsCost = !isNil(deposits) ? deposits.reduce((previousValue, nextValue) => {
    return previousValue + nextValue.amount;
  }, 0) : 0;

    /* checker for selected holiday when the disabled checkout should show */

    // Allow testing via URL like ?testdate=2025-04-18T18:30
    const urlParams = new URLSearchParams(window.location.search);
    const testDateParam = urlParams.get('testdate');
    const currDate = testDateParam ? moment(testDateParam) : moment();

    // Define date ranges for disabling checkout
    // Multiple date disable
    // Criteria:
    /*
    Customer will not be able check out on the following date/times:
    - April 16 6PM to April 19 10AM
    */
    const disabledCheckoutRanges = [
      { 
        start: moment('2025-04-16 18:00', 'YYYY-MM-DD HH:mm'), 
        end: moment('2025-04-19 10:00', 'YYYY-MM-DD HH:mm')
      },
      // Can add another dates here
      // Example:
      // { 
      //   start: moment('2025-05-01 00:00', 'YYYY-MM-DD HH:mm'), 
      //   end: moment('2025-05-01 23:59', 'YYYY-MM-DD HH:mm')
      // }
    ];

    const isCheckoutDisabled = (dateToCheck) => {
      return disabledCheckoutRanges.some(range =>
        moment(dateToCheck).isBetween(range.start, range.end, 'minute', '[]')
      );
    }

    const isDisabledCheckout = isCheckoutDisabled(currDate);

    return (
      <div className="cart-summary-col">
        <div className="cart-summary -cart-summary-col">
          <h1 className="heading">Order Summary</h1>
          <div className="summary-items-container">
            <div className="summary-items-group">
              <div className="summary-items-heading">
                <span className="summary-heading-label">Items in Cart</span>
                <span className="summary-items-total">P {printStandardDecimals(itemsInCartCost)}</span>
              </div>

              {
                itemsInCart.length > 0 &&

                <ul className="summary-items-list">

                {
                  itemsInCart.map((itemInCart, index) => {
                    return (
                      <li className="summary-item" key={index}>
                        <span className="item-name">{itemInCart.name}</span>
                        <span className="item-cost">P {printStandardDecimals(itemInCart.amount)}</span>
                      </li>
                    )
                  })
                }
              </ul>
              }
            </div>

            {!isNil(deposits) && <SummaryItemGroupContainer>
              <div className="summary-items-heading">
                <span className="summary-heading-label">Deposits</span>
                <span className="summary-items-total">P {printStandardDecimals(depositsCost)}</span>
              </div>
              <ul className="summary-items-list">

                {
                  Array.isArray(deposits) && deposits.map((deposit, index) => {
                    return (
                      <li className="summary-item" key={index}>
                        <span className="item-name">{deposit.name}</span>
                        <span className="item-cost">P {printStandardDecimals(deposit.amount)}</span>
                      </li>
                    )
                  })
                }
              </ul>
            </SummaryItemGroupContainer>}

            <SummaryItemGroupContainer>
            <div className="summary-items-heading">
                <span className="summary-heading-label">Delivery Fee</span>
                <span className="summary-items-total">P {printStandardDecimals(deliveryFee)}</span>
            </div>
            </SummaryItemGroupContainer>
          
            <section className="cart-subtotal-container -cart-summary">
              {!isNil(subtotal) && <div className="subtotal-row -cart-summary">
                <span className="label-text -cart-summary">Subtotal</span>
                <span className="subtotal-figure -cart-summary">P {printStandardDecimals(subtotal)}</span>
              </div>}


              {
                !isInCheckout ?
                <>
                  <div className="review-trigger-container">


                    {/* HOLIDAY BREAK */}

                    { isDisabledCheckout ? (
                      <OperatingTimeContent showWhenNotOperating>
                        <GradientBtn  className="review-trigger" onClick={handleSwitchToCart} block disabled>Proceed To Checkout</GradientBtn>
                      </OperatingTimeContent>
                    ) : (
                      <OperatingTimeContent>
                        <GradientBtn  className="review-trigger" onClick={handleSwitchToCart} block >Proceed To Checkout</GradientBtn>
                      </OperatingTimeContent>
                    )}


                  </div>
                  <div className="tax-info-container">
                    <p className="tax-info">*Deposits will be calculated at checkout</p>
                  </div>
                </>
                :
                <>
                <div className="review-trigger-container">

                  <GradientBtn
                    disabled={!canSubmitOrder}
                    className="review-trigger"
                    onClick={handleSubmitOrder}
                    type="submit"
                    block>Submit Order</GradientBtn>
                </div>
                <div className="tax-info-container">
                    
                    <p className="tax-info">*Tax &amp; deposits will be calculated at checkout</p>
                </div>
                </>
              }
            </section>


          </div>
        </div>
      </div>)
};

export default CartSummaryTPL;


CartSummaryTPL.propTypes = {
  cartSummary: PropTypes.object,
  handleSwitchToCart: PropTypes.func,
  handleSubmitOrder: PropTypes.func,
  isInCheckout: PropTypes.bool,
  didAgreeToTC: PropTypes.bool,
  didAgreeToPolicy: PropTypes.bool,
  handleToggleTC: PropTypes.func
};
